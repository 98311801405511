import { useRef, useState } from "react"
import { search } from "../Api"
import { Link, useNavigate } from "react-router-dom"
import { gradeLetterFromScore, textColorFromScore } from "../utils"

export default function SearchBar({ placeholder }) {
    const inputRef = useRef(null)
    const navigate = useNavigate()

    const [policyResults, setPolicyResults] = useState([])

    const PolicyResultEntry = ({ policy }) => {
        const link = `/policy/${policy.service}`
        return (
            <li className="w-100">
                <Link className="btn w-100 d-flex flex-row align-items-center justify-content-between" to={link} onClick={() => navigate(link)}>
                    <div>
                        {policy.service_favicon && <img className="d-inline-block flex-shrink-1 me-1" width={16} src={policy.service_favicon}></img>}
                        <span>{policy.service}</span>
                    </div>
                    <div className={"d-flex justify-content-center align-items-center bg-body tw-w-5 tw-h-5 rounded-2 " + textColorFromScore(policy.score)}>
                        {gradeLetterFromScore(policy.score)}
                    </div>
                </Link>
            </li>
        )
    }

    const doSearch = async () => {
        if(inputRef.current.value === "") {
            setPolicyResults([])
            return
        }
        if (!inputRef.current.value) return
        const res = await search(inputRef.current.value)
        const { policies } = res.search

        const sortedPolicies = policies.sort((a, b) => {
            const aStartsWithQuery = a.service.toUpperCase().startsWith(inputRef.current.value.toUpperCase());
            const bStartsWithQuery = b.service.toUpperCase().startsWith(inputRef.current.value.toUpperCase());
    
            if (aStartsWithQuery && bStartsWithQuery) {
                return 0;
            }
            if (aStartsWithQuery) {
                return -1;
            }
            if (bStartsWithQuery) {
                return 1;
            }
            return 0;
        });

        setPolicyResults(sortedPolicies)
    }

    const onInputChange = async e => {
        doSearch()
    }

    const onInputFocus = async e => {
        
    }

    const onInputKeyUp = async e => {
        if(e.key === 'Enter') {
            if(policyResults.length > 0) {
                navigate(`/policy/${policyResults[0].service}`)
            }
        }
    }

    const onInputBlur = async e => {
        setTimeout(() => {
            setPolicyResults([])
        }, 100)
    }

    return (
        <div onFocus={onInputFocus}>
            <div className="d-flex flex-row justify-content-center gap-2">
                <div className="position-relative tw-max-w-sm w-100">
                    <input
                        onChange={onInputChange} onBlur={onInputBlur} onKeyUp={onInputKeyUp}
                        ref={inputRef}
                        placeholder={placeholder}
                        className="w-100 rounded-pill border px-3 py-2 bg-body-secondary text-body-secondary" />
                    {!!policyResults.length && (
                        <ul className="position-absolute top-100 w-100 bg-body-tertiary rounded-4 overflow-hidden border z-2 overflow-y-scroll list-unstyled" style={{ pointerEvents: "all", userSelect: "all" }}>
                            {!!policyResults.length && policyResults.map((entry, i) => <PolicyResultEntry key={i} policy={entry} />)}
                        </ul>
                    )}
                </div>
                <button className="btn btn-primary rounded-pill px-3 fw-bold">Search</button>
            </div>
        </div>
    )
}