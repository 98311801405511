import { bgColorFromBehaviourScore, iconFromBehaviourScore, textColorFromBehaviourScore } from '../utils'

const BehaviourNotice = ({ behaviour, title, interactive }) => {
    const textColor = textColorFromBehaviourScore(behaviour.score)
    const bgColor = bgColorFromBehaviourScore(behaviour.score)
    const icon = iconFromBehaviourScore(behaviour.score)

    const interactiveClassNames = interactive ? "tw-hidden group-hover:tw-flex fade-in fade-out" : "tw-flex"

    return (
        <small className={` ${bgColor} ${textColor} px-2 rounded-4 tw-flex tw-flex-row tw-items-center tw-relative`}>
            <i className={`bi ${icon}`}></i><span className={`ms-1 ${interactiveClassNames}`}><nobr>{title}</nobr></span>
        </small>
    )
}

export default BehaviourNotice