import React from 'react'
import { bgColorFromScore } from '../utils'

export default function RatingBar({score}) {

     return(
        <div className='d-flex flex-row justify-content-center align-items-center gap-1'>
            <div className={'tw-h-2 tw-w-2 tw-rounded-full m-0 ' + (score >= 3 ? bgColorFromScore(score) : "border") } ></div>
            <div className={'tw-h-2 tw-w-2 tw-rounded-full m-0 ' + (score >= 2 ? bgColorFromScore(score) : "border")} ></div>
            <div className={'tw-h-2 tw-w-2 tw-rounded-full m-0 ' + (score >= 1 ? bgColorFromScore(score) : "border")} ></div>
            <div className={'tw-h-2 tw-w-2 tw-rounded-full m-0 ' + (score >= 0 ? bgColorFromScore(score) : "border")} ></div>
        </div>
    )  
}
