export default function SmallHeading({ title, children }) {
    return (
        <div className="bg-body d-flex flex-row justify-content-center w-100 px-3" style={{ minHeight: "30vh" }}>
            <div className="flex-grow-1 d-flex flex-row justify-content-center align-items-center tw-max-w-6xl">
                <div className="tw-max-w-5xl w-100">
                    <h1 className="display-4 fw-bold text-center">{title}</h1>
                    {children && <div className="mt-4">
                        {children}
                    </div>}
                </div>
            </div>
        </div>
    )
}