import { useLocation, Link } from "react-router-dom"
import Header from "./Header"
import Footer from "./Footer"

export default function OnePageForm({ children, errorMsg, buttonText, loading, onSubmit, back, additionalFooter }) {

    const { state } = useLocation()

    const prevState = state?.prevState

    const _onSubmit = async (e) => {
        e.preventDefault()
        onSubmit()
    }

    const spinner = (
        <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )

    const errorMsgElement = (
        <div className="text-danger pb-2">
            {errorMsg}
        </div>
    )

    return (
            <div className="flex-grow-1 OnePageForm d-flex flex-row bg-body-tertiary justify-content-center align-items-center">
                <div className="w-100 tw-max-w-xs">
                    <main className="form-signin w-100 m-auto p-3">
                        <form onSubmit={_onSubmit}>
                            {children}

                            {/* <div className="form-check text-start my-3">
                                <input className="form-check-input" type="checkbox" value="remember-me" />
                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Remember me
                                </label>
                            </div> */}
                            {errorMsg && errorMsgElement}
                            <button className="btn btn-primary w-100 py-2 mt-1" type="submit" disabled={loading}>
                                {buttonText} {loading && spinner}
                            </button>

                            <div className="mt-4 d-flex flex-row justify-content-between">
                                <p className="mb-3 text-body-secondary">&copy; 2024 Robin K</p>
                                {additionalFooter}
                            </div>
                        </form>
                    </main>
                </div>
            </div>
    )
}