import { useState } from "react";
import { bgColorFromBehaviourScore, capitalizeFirstLetter, gradeLetterFromScore, iconFromBehaviourScore, textColorFromBehaviourScore } from "../utils";
import RatingBar from "./RatingBar";


export default function BehaviourCard({ behaviour, ...props }) {

    const [expand, setExpand] = useState(false)

    const textColor = textColorFromBehaviourScore(behaviour.score)
    const bgColor = bgColorFromBehaviourScore(behaviour.score)
    const icon = iconFromBehaviourScore(behaviour.score)

    return (
        <div className={`${textColor} ${bgColor} shadow-sm p-3 rounded-3 tw-max-w-xl`} {...props}>
            <div className="d-flex flex-row align-items-center">
                <i className={`bi ${icon} me-2 h5`}></i>
                <h4 id={behaviour.type}>{behaviour.title}</h4>
            </div>
            <div>
                {/* <p className="text-body-secondary">{capitalizeFirstLetter(behaviour.reason)}</p> */}
                <div className="tw-min-h-fit" onClick={() => setExpand(!expand)}>
                    {!expand && <p className="mb-0 underline tw-cursor-pointer">Show Source</p>}
                    {expand && <p><i className="bi bi-quote me-1"></i><i>{expand && behaviour.reference_paragraph}</i></p>}
                </div>
            </div>
        </div>
    )
}