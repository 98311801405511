import React, { useContext, useEffect, useState } from "react";
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { API_URL, listFeaturedPolicies, listPolicies, listRandomPolicies } from '../Api';
import { capitalizeFirstLetter, gradeLetterFromScore, textColorFromScore } from '../utils';
import PolicyCard from '../components/PolicyCard';
import Section from '../components/Section';
import PolicyCardList from "../components/PolicyCardList";
import SearchBar from "../components/SearchBar";
import PolicyIconList from "../components/PolicyIconList";
import { AuthContext } from "../providers/AuthProvider";
import { ApplicationContext } from "../providers/ApplicationProvider";
import PricingCards from "../components/PricingCards";
import SubscribeFormButton from "../components/SubscribeFormButton";
import LinkCard from "../components/LinkCard";

import about_0 from "../img/about_0.png"
import { Accordion } from "react-bootstrap";

export async function loader({ }) {
    const [{ policies: featuredPolicies }, { policies: randomPolicies }] = await Promise.all([
        listFeaturedPolicies(),
        listRandomPolicies()
    ]);

    return { featuredPolicies, randomPolicies };
}

export default function HomePage() {
    const { featuredPolicies, randomPolicies } = useLoaderData();
    const { isGuestUser, isFreeUser, isPaidUser } = useContext(AuthContext);
    const { setShowSignUpModal, setShowSubscribeModal } = useContext(ApplicationContext)
    const [isYearly, setIsYearly] = useState(true)

    const handlePlan = (fromPlan) => {
        if (fromPlan === "monthly") {
            setIsYearly(false)
        } else if (fromPlan === "yearly") {
            setIsYearly(true)
        }

    }

    const selectedPlanClassName = "bg-body-secondary"

    const scanCustomPolicyLinkClicked = e => {
        if (isGuestUser) {
            e.preventDefault()
            setShowSubscribeModal(true, "Scan Custom Policies")
        }
        else if (isFreeUser) {
            e.preventDefault()
            setShowSubscribeModal(true, "Subscribe to Scan")
        }
    }

    const InformationCard = (props) => {
        return (
            <li className="flex-grow-1 bg-body text-center p-3 py-5 rounded shadow-sm border hover:tw-text-primary tw-transition-colors tw-relative overflow-hidden">
                <div className="tw-absolute tw-w-full tw-h-full tw-bottom-0">
                    <i className={"d-block bi me-2 tw-text-[300px] left-1/2 tw-absolute text-secondary tw-z-0 tw-opacity-5 " + props.icon}></i>
                </div>
                <h2 className="fw-bold"><i className={"bi me-2 text-primary " + props.icon}></i>{props.title}</h2>
                <p style={{ maxWidth: "400px" }} className="m-auto position-relative z-2 text-secondary">{props.paragraph}</p>
            </li>
        )
    }

    return (
        <div>
            <div className="d-flex flex-row justify-content-center w-100 px-3" style={{ minHeight: "60vh" }}>
                <div className="flex-grow-1 d-flex flex-row justify-content-center align-items-center tw-max-w-6xl">
                    <div className="tw-max-w-5xl text-center">
                        <h1 className="display-4 fw-bold">We read <nobr>privacy policies</nobr></h1>
                        <p className="fs-5">
                            {/* <u className="text-danger">87%</u> of people accept privacy policies without reading them. */}
                            ...so you don't have to.
                        </p>
                        <div className="d-flex flex-row justify-content-center gap-2 mb-3">
                            <Link to={"/policies"} className="btn btn-primary rounded-pill px-3 fw-bold">View All Policies</Link>
                            <Link onClick={scanCustomPolicyLinkClicked} to={"/scan-policy"} className="btn btn-outline-primary rounded-pill px-3 fw-bold">Scan Policy</Link>

                        </div>
                    </div>
                </div>
            </div>
            <Section className="bg-body-tertiary">
                <h2>Featured Policies</h2>
                <div className="d-flex flex-row">
                    <PolicyCardList policies={featuredPolicies} showCategory={true} moreText={"View All"} moreTo={"/policies"} />
                </div>
                <p className="mt-3"><a className="fs-6 text-body-tertiary" href="/feature-policy">Feature your service!</a></p>
            </Section>
            <Section className="bg-body-secondary">
                <h2>Popular Lists</h2>
                <ul className="list-unstyled d-flex flex-row flex-wrap justify-content-start gap-3">
                    <li><LinkCard alt={"🔒 VPN Services"} color={"success"} title={"Most Secure VPNs"} to={"/policies/VPN services"}
                        text={"See what VPN services know least about you and your browsing habits."} action={"See VPN List"} /></li>
                    <li><LinkCard alt={"📨 Messaging"} color={"primary"} title={"Most Private Messaging Apps"} to={"/policies/messaging"}
                        text={"What messaging service collects the least amount information about you?"} action={"See Messaging List"} /></li>
                    <li><LinkCard alt={"🎀 Female Health"} color={"danger"} title={"Most Private Period Trackers"} to={"/policies/period trackers"}
                        text={"Discover what apps not to trust with your most sensitive personal data."} action={"See List"} /></li>
                </ul>
            </Section>
            <Section className="bg-body-tertiary">
                <div className="d-flex flex-column flex-md-row gap-4">
                    <div className="tw-max-w-xl">
                        <h2 className="h1 fw-bold">Transparency at a Glance</h2>
                        <p className="fs-5 mb-0">
                            {/* <span className="text-primary">87% of people</span> accept privacy policies without reading them. */}
                            Transform thousands of pararaphs of legal jargon into a simple, transparent ranking. We strive to make the internet more Privacy-concious.
                        </p>
                    </div>
                    <div className="overflow-x-hidden me-3">
                        <PolicyIconList policies={randomPolicies} />
                    </div>
                </div>
            </Section>
            <Section className="bg-body-tertiary">
                <div>
                    <h2 className="h1 fw-bold">Features</h2>
                    <ul className="list-unstyled d-flex flex-row flex-wrap py-4 gap-3 fs-5">
                        <InformationCard title="Rank Services by Privacy" paragraph={<span>Every service is given a rating from <b>A</b> to <b>E</b>, along with a list of collected information about you.</span>} icon="bi-bar-chart-fill" />
                        <InformationCard title="Compare Services" paragraph={<span>Easily choose the most Privacy-friendly alternative to your favorite services. Reward companies for caring about you.</span>} icon="bi-book" />
                        <InformationCard title="Ask Questions" paragraph={<span>Ask specific questions about a service to our privacy assistant.</span>} icon="bi-chat-fill" />
                        <InformationCard title="Scan Policies" paragraph={<span>Are you interested in a policy that is not listed? <Link onClick={scanCustomPolicyLinkClicked} to="/scan-policy">Easily submit</Link> your own policies.</span>} icon="bi-radar" />
                    </ul>
                </div>
            </Section>
            <Section paddingBottom={0}>
                <div className="text-center" id="pricing">
                    <h2 className="display-5  fw-bold">Compare Plans</h2>
                    <div>

                        <div className="input-group d-flex justify-content-center mb-5">
                            <button className={"btn btn-outline-secondary " + (!isYearly ? selectedPlanClassName : "")} onClick={() => handlePlan("monthly")}>Monthly</button>
                            <button className={"btn btn-outline-secondary " + (isYearly ? selectedPlanClassName : "")} onClick={() => handlePlan("yearly")}>Yearly <span className="text-warning bg-warning-subtle rounded ms-1 px-2">Save 33%</span></button>
                        </div>
                    </div>
                    <PricingCards isYearly={isYearly} />
                </div>
            </Section>
            <Section>
                <h2 className="display-6 text-center mb-4 mt-4 fw-bold">Frequently Asked Questions</h2>
                <div>
                    <div className="tw-max-w-2xl m-auto">
                        <Accordion className="fs-5" defaultActiveKey="2">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><span className="fs-5">Who is behind the service?</span></Accordion.Header>
                                <Accordion.Body>
                                    Privacy Police was started and is run by two brothers from Sweden, a country known for strong privacy laws.
                                    We both share the notion that privacy is a universal right, and want to help people
                                    reward companies that actually care about you and your data.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span className="fs-5">What data do you collect, how do you use it?</span></Accordion.Header>
                                <Accordion.Body>
                                    We do not use any tracking technologies on our website. When visiting us, all your
                                    information is secure and never used for tracking or advertisement purposes.
                                    We collect your email and some of your payment information to process subscriptions through our partner Stripe.
                                    Try looking at our own <Link to={"/policy/Privacy Police"}>privacy policy summary</Link>.
                                    For analytics, we use <a href="https://www.simpleanalytics.com/">Simple Analytics</a>, a privacy-friendly analytics platform.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><span className="fs-5">How does the ranking work?</span></Accordion.Header>
                                <Accordion.Body>
                                    We read privacy policy pages, extract what key
                                    information each service collects from its users, and present them on our website
                                    with a list of all collected information, including if the company sells or shares some of that information.
                                    More details are available on our <Link to={"/about"}>about page</Link>.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </Section>
            <Section className="bg-body-tertiary">
                <div className="text-center">
                    <h2 className="h1 fw-bold">Try it!</h2>
                    <p className="text-body-secondary fs-5">
                        Start comparing services, or {isGuestUser ? "sign up" : "upgrade"} for more features.
                    </p>
                    <div className="d-flex flex-row justify-content-center gap-2">
                        <Link to={"/policies"} className="btn btn-outline-primary">Compare Services</Link>
                        {isGuestUser ? <Link to={"/signup"} className="btn btn-primary">Sign up</Link> : (isPaidUser ? null : <Link to={"/#pricing"} className="btn btn-primary">Upgrade for $2/mo!</Link>)}
                    </div>
                </div>
            </Section>
        </div>
    );
}
