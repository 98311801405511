import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";
import { ApplicationContext } from "../providers/ApplicationProvider";

export default function Header() {
    const { isGuestUser, isFreeUser } = useContext(AuthContext)
    const { setShowSignUpModal, setShowSubscribeModal } = useContext(ApplicationContext)
    const [navbarExpanded, setNavbarExpanded] = useState()

    const scanCustomPolicyLinkClicked = e => {
        if (isGuestUser) {
            e.preventDefault()
            setShowSubscribeModal(true, "Scan Custom Policies")
        }
        else if (isFreeUser) {
            e.preventDefault()
            setShowSubscribeModal(true, "Subscribe to Scan")
        }
    }

    return (
        /*sticky-top shadow-sm*/
        <header className=" p-1 bg-body-tertiary">
            <nav className="navbar navbar-expand-lg w-full bg-body-tertiary">
                <div className="m-auto tw-max-w-6xl container-fluid">
                    <div className="d-flex flex-row gap-1 align-items-center">
                        <Link className="m-0 navbar-brand link-underline link-underline-opacity-0 h2 text-body fw-bold" to="/">Privacy Police</Link>
                        <small className="bg-primary-subtle text-primary-emphasis px-1 rounded-2 fw-bold">BETA</small>
                    </div>
                    <button className="navbar-toggler" onClick={() => { setNavbarExpanded(!navbarExpanded) }} type="button" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={"collapse navbar-collapse " + (navbarExpanded ? "show" : "")}>
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <Link className="nav-link link-underline link-underline-opacity-0 link-underline-opacity-100-hover text-body px-2" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link link-underline link-underline-opacity-0 link-underline-opacity-100-hover text-body px-2" to="/about">About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link link-underline link-underline-opacity-0 link-underline-opacity-100-hover text-body px-2" to="/policies">All Policies</Link>
                            </li>
                            <li className="nav-item">
                                <Link onClick={scanCustomPolicyLinkClicked} className="nav-link link-underline link-underline-opacity-0 link-underline-opacity-100-hover text-body px-2" to="/scan-policy">Scan Policy</Link>
                            </li>
                            {/* <Link className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover text-body px-2" to="/ranking">Ranking</Link> */}
                            {/* <Link className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover text-body px-2" to="/alternatives">Alternatives</Link> */}

                            <div className="nav-item d-flex align-items-center">
                                <i className="bi bi-dot text-body-secondary"></i>
                                {isGuestUser ? <Link className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover text-body px-2" to="/signup">Sign Up</Link> : <Link className="link-underline link-underline-opacity-0 link-underline-opacity-100-hover text-body px-2" to="/account">Account</Link>}
                            </div>
                        </ul>

                    </div>
                </div>
            </nav>

        </header>
    )
}