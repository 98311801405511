import { Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import Checkmark from "../components/Checkmark"
import { useEffect } from "react"

export default function CheckoutPage({ }) {
    const { searchParams: query, setSearchParams } = useSearchParams()
    const navigate = useNavigate()

    const cancelled = query?.cancelled == "true"
    const success = query?.success == "true"

    if (cancelled) {
        return <Navigate to={"/account"} replace />
    }

    if (success) {
        return (
            <div className="flex-grow-1 d-flex flex-row bg-body-tertiary justify-content-center align-items-center">
                <div className="tw-max-w-2xl">
                    <main className="w-100 m-auto p-3 text-center">
                        <Checkmark className={"text-primary mb-4"} />
                        <p className="fs-5">Your subscription was successfully created!</p>
                        <p className="text-center text-body-secondary"><a href="/">Click here to start using Privacy Police.</a></p>
                    </main>
                </div>
            </div>
        )
    }

    return <Navigate to={"/"} replace />
}