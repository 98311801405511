import { useContext, useEffect, useRef, useState } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { Link, Navigate, useLoaderData } from "react-router-dom"
import { ApplicationContext } from "../providers/ApplicationProvider"
import SmallHeading from "../components/SmallHeading"
import Section from "../components/Section"
import { getScans, scanPolicy } from "../Api"
import { ProgressBar } from "react-bootstrap"
import ConfirmModal from "../modals/ConfirmModal"

let loadingIntervalId = undefined

export async function loader({ }) {
    const res = await getScans()
    return res;
}

export default function ScanPolicyPage({ }) {
    const { policies } = useLoaderData()

    const { setShowSubscribeModal } = useContext(ApplicationContext)
    const { user, isGuestUser, isFreeUser, isPaidUser } = useContext(AuthContext)

    const inputRef = useRef(null)

    const [showErrorModal, setShowErrorModal] = useState(false)
    const [error, setError] = useState(null)

    const [loadingProgress, setLoadingProgress] = useState(0)
    const [policy, setPolicy] = useState(null)

    const onSubmit = e => {
        e.preventDefault()

        setError(null)
        setLoadingProgress(0)

        const url = inputRef.current.value

        let _loadingProgress = 0
        const updateProgress = () => {
            setLoadingProgress(_loadingProgress + (100 - _loadingProgress) / 3)
        }

        setLoadingProgress(_loadingProgress)
        loadingIntervalId = setInterval(updateProgress, 2000)

        scanPolicy(url).then(res => {
            setPolicy(res.policy)
            window.location.href = "/policy/" + (res.policy.user_content_id ?? res.policy.service)
            setLoadingProgress(100)
        }).catch(err => {
            setError(err.message)
            setShowErrorModal(true)
            setLoadingProgress(0)
        }).finally(() => {
            clearInterval(loadingIntervalId)
        })

    }

    const UserPolicyEntry = ({ policy }) => {
        const to = `/policy/${policy.user_content_id}`
        return (
            <div className="border-end border-bottom rounded-4 bg-body shadow-sm w-100 tw-group">
                <div className="h-100 d-flex flex-row justify-content-between position-relative">
                    <div className="p-3">
                    <h4>{policy.service}</h4>
                    </div>
                    <div className="p-3">
                        <Link to={to}><i className="bi bi-arrow-right stretched-link"></i></Link>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        clearInterval(loadingIntervalId)
        return () => clearInterval(loadingIntervalId)
    }, [])

    if (user && !isPaidUser) return <Navigate to={"/#pricing"} replace />

    const spinner = (
        <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )

    const loading = loadingProgress != 0 && loadingProgress != 100

    return (
        <div className="tw-min-h-[80vh] bg-body-tertiary">
            <ConfirmModal
                show={showErrorModal}
                title={"Error"}
                description={error}
                confirmText={"OK"}
                doCancel={false}
                onCancel={() => setShowErrorModal(false)}
                onConfirm={() => setShowErrorModal(false)}
            />
            <SmallHeading title={"Scan Policy"}>
                <form onSubmit={onSubmit} className="d-flex flex-row justify-content-center gap-2">
                    <div className="tw-max-w-sm w-100">
                        <input
                            disabled={loading}
                            type="url"
                            ref={inputRef}
                            placeholder={"Policy URL"}
                            className="w-100 rounded-pill border px-3 py-2 bg-body-secondary text-body-secondary"
                        />
                    </div>
                    <button type="submit" className="btn btn-primary rounded-pill px-3 fw-bold">{loading ? spinner : "Scan Policy URL"}</button>
                </form>
            </SmallHeading>
            <Section className={"bg-body-tertiary"}>
                <ProgressBar now={loadingProgress} />
            </Section>
            <Section className={"bg-body-tertiary"}>
                <h3>Recent Scans</h3>
                {policies.map(policy => <UserPolicyEntry key={policy.user_content_id} policy={policy} />)}
            </Section>
        </div>
    )
}