import React from "react";
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { getCategory, listCategories, listPolicies } from "../Api";
import Section from "../components/Section";
import PolicyCardList from "../components/PolicyCardList";
import { capitalizeAllWords, capitalizeFirstLetter } from "../utils";
import SmallHeading from "../components/SmallHeading";
import SearchBar from "../components/SearchBar";

export async function loader({ params }) {
    const res = await getCategory(params.category)
    return res;
}

export default function CategoryPage() {
    const { policies, category } = useLoaderData()

    return (
        <div>
            <SmallHeading title={capitalizeAllWords(category.name)}>
                <h5 className="m-0 text-center text-body-secondary">{category.policy_count} policies have been scanned</h5>
            </SmallHeading>
            <Section className="bg-body-tertiary">
                <PolicyCardList policies={policies} />
            </Section>
        </div>
    )
}
