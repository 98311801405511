import { useContext } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { ApplicationContext } from "../providers/ApplicationProvider"
import { API_URL } from "../Api"

export default function SubscribeFormButton({ buttonClassName, period }) {

    const { setShowSignUpModal, setShowSubscribeModal } = useContext(ApplicationContext)
    const { user, isGuestUser } = useContext(AuthContext)

    const onSubscribeFormSubmit = e => {
        if (isGuestUser) {
            e.preventDefault()
            setShowSubscribeModal(true)
        }
    }

    return (
        <form method="POST" action={API_URL + "/create-checkout-session?plan=pro&period=" + period} onSubmit={onSubscribeFormSubmit}>
            <button type="submit" className={buttonClassName || "btn btn-primary fw-bold"}>Subscribe<i className="bi bi-arrow-right-short"></i></button>
        </form>
    )
}