import { useState } from "react";
import { capitalizeFirstLetter, gradeLetterFromScore } from "../utils";
import RatingBar from "./RatingBar";


export default function InformationCard({ information, ...props }) {

    const [expand, setExpand] = useState(false)

    return (
        <div className="bg-body shadow-sm p-3 rounded-3 tw-max-w-xl" {...props}>
            <div className="d-flex flex-row justify-content-between">
                <h4 id={information.type}>{information.title}</h4>
                <RatingBar score={information.score}/>
            </div>
            <div>
                <p className="text-body-secondary">{capitalizeFirstLetter(information.reason)}</p>
                <div className="text-body-tertiary tw-min-h-fit" onClick={() => setExpand(!expand)}>
                    {!expand && <p className="mb-0 underline tw-cursor-pointer">Show Source</p>}
                    {expand && <p><i className="bi bi-quote me-1"></i><i>{expand && information.reference_paragraph}</i></p>}
                </div>
            </div>
        </div>
    )
}