import { Link } from "react-router-dom";
import PolicyCard from "./PolicyCard";

export default function PolicyCardList({ policies, showCategory, moreText, moreTo }) {
    const ViewMoreCard = ({ }) => {
        return (
            <Link className="btn rounded-4 tw-h-12 md:tw-h-56 tw-w-80 md:tw-w-20 tw-group d-flex flex-column justify-content-center HoverButton" to={moreTo}>
                <span className="text-secondary"><small>{moreText}</small></span>
                <i className="bi bi-arrow-right text-secondary"></i>
            </Link>
        )
    }

    return (
        <>
            <div className="d-flex flex-row flex-wrap gap-3 ">
                {policies && policies.map(policy => <PolicyCard key={policy.service} policy={policy} showCategory={showCategory} />)}
                {moreTo && <ViewMoreCard />}
            </div>
        </>
    )
}