import { createContext, useCallback, useContext, useEffect, useState } from "react"
import * as Api from "../Api"
import { Outlet } from "react-router-dom"
import { AuthContext } from "./AuthProvider"

export const UserSettingsContext = createContext({})

export const UserSettingsProvider = ({ }) => {
    const { user, isGuestUser, refreshUser } = useContext(AuthContext)

    const [bookmarks, setBookmarks] = useState([])
    const [notifications, setNotifications] = useState([])

    const save = async (bookmarks) => {
        if (isGuestUser) {
            window.localStorage.setItem("bookmarks", JSON.stringify(bookmarks))
        }
        else {
            await Api.setBookmarks(bookmarks)
        }
    }

    const load = () => {
        if (isGuestUser) {
            const bookmarksRaw = window.localStorage.getItem("bookmarks")

            let bookmarks

            try {
                if (bookmarksRaw) bookmarks = JSON.parse(bookmarksRaw)
                else bookmarks = []
            }
            catch {
                bookmarks = []
            }

            return { bookmarks }
        }
        else {
            return user
        }
    }

    const refresh = useCallback(async () => {
        if (!load()) return
        const { bookmarks, notifications } = load()
        setBookmarks(bookmarks)
        setNotifications(notifications)
    })

    const isBookmarked = (service) => bookmarks && !!bookmarks.find(x => x === service)

    const addBookmark = (service) => {
        if (!bookmarks.find(x => x === service)) {
            const newBookmarks = [...bookmarks, service]
            setBookmarks(newBookmarks)
            save(newBookmarks)
        }
    }

    const removeBookmark = (service) => {
        const newBookmarks = bookmarks.filter(x => x !== service)
        setBookmarks(newBookmarks)
        save(newBookmarks)
    }

    const isNotification = (service) => notifications && !!notifications.find(x => x === service)

    const addNotification = (service) => {
        Api.addNotification(service).then(res => {
            setNotifications(res.notifications)
        })
    }

    const removeNotification = (service) => {
        Api.removeNotification(service).then(res => {
            setNotifications(res.notifications)
        })
    }

    useEffect(() => {
        if (user != null) {
            refresh()
        }
    }, [user])

    return (
        <UserSettingsContext.Provider value={{
            bookmarks,
            isBookmarked,
            addBookmark,
            removeBookmark,
            notifications,
            isNotification,
            addNotification,
            removeNotification
        }}>
            <Outlet />
        </UserSettingsContext.Provider>
    )
}