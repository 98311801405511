import { Link } from "react-router-dom";
import Section from "../components/Section";
import SmallHeading from "../components/SmallHeading";

export default function PrivacyPolicyPage({ }) {
    return (
        <div>
            <SmallHeading title={"Privacy Policy"}>
                <div className="fs-5 text-center">
                    <Link to="/policy/Privacy Police">Our Ranking</Link>
                </div>
            </SmallHeading>
            <Section className="fs-5 bg-body-tertiary">
                <div className="tw-max-w-4xl">
                    <p>Welcome to Privacy Police. We respect your privacy and are committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services.</p>

                    <h2>1. Information We Collect</h2>
                    <p>We collect the following types of information from our users:</p>

                    <h3>a. Email Address</h3>
                    <p><strong>Purpose:</strong> We collect your email address when you create an account on Privacy Police. This is used for account creation, login functionality, and communication regarding your account and our services.</p>

                    <h3>b. Hashed Password</h3>
                    <p><strong>Purpose:</strong> To ensure the security of your account, we collect and store a hashed version of your password. This is necessary for account creation and login functionality.</p>

                    <h3>c. Billing Information</h3>
                    <p><strong>Purpose:</strong> If you sign up for a paid plan, we collect billing information, including your name, address, and payment details. This information is required by Stripe, our payment processor, to process payments. Privacy Police itself does not store full payment details on our servers.</p>

                    <h2>2. How We Use Your Information</h2>
                    <p>We use the information we collect for the following purposes:</p>
                    <ul>
                        <li><strong>Account Creation and Management:</strong> To set up and manage your account on Privacy Police.</li>
                        <li><strong>Communication:</strong> To send you updates, notifications, and other information related to your account.</li>
                        <li><strong>Billing and Payments:</strong> To process payments through Stripe if you sign up for a paid plan.</li>
                    </ul>

                    <h2>3. Sharing Your Information</h2>
                    <p>We do not sell, trade, or rent your personal information to others. We share your information only in the following circumstances:</p>
                    <ul>
                        <li><strong>With Service Providers:</strong> We share your email address with Stripe, our payment processor, for the purpose of processing payments. </li>
                    </ul>

                    <h2>4. Data Security</h2>
                    <p>We implement a variety of security measures to maintain the safety of your personal information. Your password is stored in a hashed format, and your billing information is securely transmitted to Stripe for payment processing.</p>

                    <h2>5. Your Rights</h2>
                    <p>You have the right to:</p>
                    <ul>
                        <li><strong>Access Your Information:</strong> Request a copy of the personal information we hold about you.</li>
                        <li><strong>Update Your Information:</strong> Update or correct any inaccuracies in your personal information.</li>
                        <li><strong>Delete Your Information:</strong> Request the deletion of your account and personal information from our systems.</li>
                    </ul>
                    <p>To exercise any of these rights, please contact us at privacy@privacypolice.ai.</p>

                    <h2>6. Third-Party Links</h2>
                    <p>Our website may contain links to other websites. We are not responsible for the privacy practices of these third-party websites. We encourage you to review the privacy policies of any website you visit.</p>

                    <h2>7. Changes to This Privacy Policy</h2>
                    <p>We may update this Privacy Policy from time to time to reflect changes in our practices. We will notify you of any significant changes by posting the new policy on our website with an updated effective date.</p>

                    <h2>8. Contact Us</h2>
                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
                    <p>
                        <strong>Email:</strong> privacy@privacypolice.ai
                    </p>

                    <p>By using our website and services, you consent to the collection and use of your information as described in this Privacy Policy.</p>
                </div>
            </Section>
        </div>
    )
}