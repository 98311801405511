import html2canvas from "html2canvas"
import { useContext, useEffect, useRef, useState } from "react"
import { Modal } from "react-bootstrap"
import { PolicyCardBase } from "../components/PolicyCardBase"
import { ApplicationContext } from "../providers/ApplicationProvider"
import { getBehaviourInList, getImage, gradeLetterFromScore, parseDate } from "../utils"
import RatingBar from "../components/RatingBar"
import BehaviourNotice from "../components/BehaviourNotice"

export default function SharePolicyModal({ show, policy }) {

    const { setShowShareModal } = useContext(ApplicationContext)
    const [image, setImage] = useState(null)
    const [message, setMessage] = useState("")
    const [date, setDate] = useState(0)
    const componentRef = useRef(null);

    const shareLink = `${window.location.origin}/policy/${policy.service}`
    const shareTitle = `Policy`
    const shareText = `${policy.service} has a privacy score of ${gradeLetterFromScore(policy.score)}!\n\nPrivacy Police reads privacy policies so you don't have to:`
    const shareTwitter = `https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fprivacypolice.ai%2F&text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareLink)}`
    const shareFacebook = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareLink)}`;
    const shareLinkedIn = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareLink)}`;

    const handleImage = async (element) => {
        setImage(await getImage(element))
    }

    useEffect(() => {
        setMessage("")
        setDate(parseDate(new Date()))
        handleImage(componentRef.current)
    }, [policy])

    const onCancel = () => {
        setShowShareModal(false)
    }

    const copyLink = () => {
        navigator.clipboard.writeText(shareLink)
        setMessage("Link copied to clipboard!")
    }

    const copyImage = async () => {
        try {
            const canvas = await html2canvas(componentRef.current);
            const blob = await new Promise(resolve => canvas.toBlob(resolve));
            const item = new ClipboardItem({ "image/png": blob });

            if (navigator.clipboard && navigator.clipboard.write) {
                await navigator.clipboard.write([item]);
                setMessage("Image copied to clipboard!");
            } else {
                throw new Error("Clipboard write not supported");
            }
        } catch (err) {
            if (err.name === "NotAllowedError") {
                alert("Clipboard permission denied. Please adjust your browser settings to allow clipboard access.");
            }
            else {
                console.error("Error copying image to clipboard:", err);
            }
        }
    }

    const sellsUserData = getBehaviourInList(policy.evaluation.behaviours, "shares_user_data_for_money")
    const sharesUserData = getBehaviourInList(policy.evaluation.behaviours, "shares_user_data")
    const sharesAnyData = sellsUserData || sharesUserData

    const TextItem = ({ information, i }) => {
        return (
            <li key={information.type + i} className={"d-flex flex-row text-secondary gap-3 justify-content-between"}>{information.title}<RatingBar score={information.score} /></li>
        )
    }

    const SharePolicyCard = ({ policy }) => {
        return (
            <div className="bg-body p-3 rounded-4 tw-min-h-56 tw-w-72 md:tw-w-96">
                <div className="d-flex justify-content-between gap-2 mb-1">
                    <div>
                        <div className="d-flex align-items-center">
                            {policy.service_favicon ? <img className="d-inline-block flex-shrink-1 me-1" width={20} src={policy.service_favicon}></img> : <div className="rounded-circle tw-w-6 tw-h-6 bg-primary  me-1 d-flex justify-content-center align-items-center w-full"><span className="text-white tw-text-xs mb-0">{policy.service[0]}</span></div>}
                            <h2 className="mb-0">{policy.service}</h2>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center gap-2 rounded-2">
                        <span className="fs-5 text-body-tertiary">Rating</span><span className="fs-3">{gradeLetterFromScore(policy.score)}</span>
                    </div>
                </div>
                {sharesAnyData && <div className="tw-h-5 d-flex flex-row align-items-center mb-1">
                    {sellsUserData && <BehaviourNotice behaviour={sellsUserData} title={"May sell data to third parties"} />}
                    {!sellsUserData && sharesUserData && <BehaviourNotice behaviour={sharesUserData} title={"May share data with third parties"} />}
                </div>}
                <ul className="px-0 list-untstyled mb-1 mx-1">
                    {policy.evaluation.information_collected.sort((a, b) => b.score - a.score).slice(0, 5).map((information, i) => <TextItem key={i} information={information} i={i} />)}
                </ul>
            </div>
        )
    }

    return (
        <>
            <Modal show={show} centered onHide={onCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Share {policy.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column">
                        {/* <p>Select a platform to share:</p> */}

                        <div className="d-flex flex-row gap-2 w-100 d-flex flex-column  align-items-center tw-relative " >
                            <div className="py-4 bg-body-secondary rounded-2 tw-z-40"  >
                                <div className="p-3 bg-body-secondary" ref={componentRef}>
                                    <div className=" tw-pt-4" >
                                        {/* <h2 className="h1 fs-3 fw-bold">Privacy rating: </h2> */}
                                        <div className="bg-body-secondary " >
                                            <SharePolicyCard policy={policy} />
                                            {/* <PolicyCardBase policy={policy} /> */}
                                        </div>
                                        <div className="d-flex justify-content-between px-2">
                                            <p className="text-secondary mb-0 mt-1 font-thin"><small> <i className="bi bi-clock me-1"></i>{date}</small></p>
                                            <p className="text-secondary mb-0 mt-1 font-thin"><small> <i className="bi bi-globe me-1"></i>privacypolice.ai</small></p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="tw-absolute tw-w-10 tw-z-10">
                                <img src={image} alt="Image" className="tw-w-full" />
                            </div>
                        </div>
                        <span className="text-secondary my-2 px-4"><small>Share via...</small></span>
                        <div className="d-flex flex-row tw-justify-center align-items-center flex-wrap w-100 mb-0 px-4 md:tw-justify-between">
                            <a className="btn rounded-2 d-flex flex-column justify-content-center align-items-center hover:tw-bg-[#dee2e680]" href={`mailto:?subject=Check out this privacy policy&body=I thought you might be interested in this privacy policy: ${shareLink}`}>
                                <i className="bi bi-envelope"></i>
                                <span className="text-secondary"><small>Mail</small></span>
                            </a>
                            {/* FACEBOOK VILL ANVÄNDA NÅN JÄVKA CP SDK SOM AJG INTE PALLAR FIXA JUST NU */}
                            {/* <a className="btn rounded-2 d-flex flex-column justify-content-center align-items-center hover:tw-bg-[#dee2e680]" href={shareFacebook}>
                                <i className="bi bi-facebook text-primary"></i>
                                <span className="text-secondary"><small>Facebook</small></span>
                            </a> */}
                            <a className="btn rounded-2 d-flex flex-column justify-content-center align-items-center hover:tw-bg-[#dee2e680]" href={shareTwitter} target="_blank">
                                <i className="bi bi-twitter-x text-primary"></i>
                                <span className="text-secondary"><small>Twitter/X</small></span>
                            </a>
                            <a className="btn rounded-2 d-flex flex-column justify-content-center align-items-center hover:tw-bg-[#dee2e680]" href={shareLinkedIn} target="_blank">
                                <i className="bi bi-linkedin text-primary"></i>
                                <span className="text-secondary"><small>Linkedin</small></span>
                            </a>
                            <button className="btn rounded-2 d-flex flex-column justify-content-center align-items-center hover:tw-bg-[#dee2e680]" onClick={copyImage}>
                                <i className="bi bi-copy text-warning"></i>
                                <span className="text-secondary"><small>Image</small></span>
                            </button>
                            {/* {isShareable ? <button className="btn btn-outline-danger rounded-2" onClick={() => handleShare("upload")}>
                                <i className="bi bi-upload"></i>
                            </button> : null} */}
                        </div>
                        <span className="text-secondary my-2 px-4"><small>Or copy link</small></span>
                        <div className="d-flex flex-row justify-content-between align-items-center w-100 mb-1 px-4">
                            <div className="d-flex flex-row align-items-center w-100">
                                {/* <input className="form-control tw-max-w-32" readOnly value={shareLink} /> */}
                                <div className="input-group w-100">
                                    <input type="text" className="form-control btn-outline-primary text-secondary" readOnly value={shareLink} />
                                    <button className="btn btn-outline-secondary" type="button" onClick={copyLink}><i className="bi bi-copy"></i></button>
                                </div>
                            </div>
                            
                        </div>
                        
                        <p className="text-secondary text-center px-4 mb-0 tw-min-h-6"><small>{message}</small></p>

                    </div>

                </Modal.Body>
                {/* <Modal.Footer>
                    <div className="d-flex flex-row gap-2 w-100 mx-auto my-0" style={{ maxWidth: "300px" }}>
                        <button onClick={onCancel} className="btn btn-outline-primary rounded-pill flex-grow-1">{"No"}</button>
                        <button onClick={onConfirm} className="btn btn-primary rounded-pill flex-grow-1">{"Yes"}</button>
                    </div>
                </Modal.Footer> */}
            </Modal>
        </>

    )
}