import { useContext } from "react"
import { Modal } from 'react-bootstrap'
import { ApplicationContext } from '../providers/ApplicationProvider'
import SignUpForm from "../components/SignUpForm"

export default function SignUpModal({ show, title }) {
    const { setShowSignUpModal } = useContext(ApplicationContext)

    const onCancel = () => {
        setShowSignUpModal(false)
    }

    const onConfirm = () => {

    }

    return (
        <>
            <Modal dialogClassName="tw-max-w-sm" show={show} centered onHide={onCancel}>
                <SignUpForm title={title} />
            </Modal>
        </>

    )
}