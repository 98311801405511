import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, redirect, useLoaderData, useNavigate } from 'react-router-dom';
import { getPolicy, getUser, listPolicies, requestPasswordReset, setBookmarks } from '../Api';
import { AuthContext } from "../providers/AuthProvider";
import Section from "../components/Section";
import { UserSettingsContext } from "../providers/UserSettingsProvider";
import { capitalizeFirstLetter, gradeLetterFromScore } from "../utils";
import ProSubscriptionFeatures from "../components/ProSubscriptionFeatures";
import ManageSubscriptionButton from "../components/ManageSubscriptionButton";
import ConfirmModal from "../modals/ConfirmModal";
import Checkmark from "../components/Checkmark";

export async function loader({ params }) {
    // const user = await getUser()
    return null
}


export default function AccountPage() {
    // const {  } = useLoaderData();
    const { user, isGuestUser, isFreeUser, logout } = useContext(AuthContext)
    const { bookmarks, removeNotification, refresh, notifications } = useContext(UserSettingsContext)
    // Viktigt: kolla så user != null (if user) så vi kan garantera att den har laddat innan vi kör isGuestUser
    const [notificationPolicies, setnotificationPolicies] = useState([])

    const fetchPolicies = async () => {
        const filteredPolicies = await Promise.all(notifications.map(async (service) => {
            const res = await getPolicy(service)
            return res.policy
        }))
        setnotificationPolicies(filteredPolicies)
    }

    // useEffect(() => {
    //     if (notifications == null) return
    //     if (user){
    //         fetchPolicies()
    //         console.log()
    //     }

    // }, [notifications])

    const [showConfirmChangePasswordModal, setShowConfirmChangePasswordModal] = useState(false)
    const [showChangePasswordSuccessfulModal, setShowChangePasswordSuccessfulModal] = useState(false)

    const onConfirmChangePasswordModalConfirm = () => {
        setShowChangePasswordSuccessfulModal(true)
    }

    if (!user) {
        return <></>
    }
    if (user && isGuestUser) {
        return <Navigate to={"/signup"} replace={true} />
    }

    const planValidUntil = new Date(user.planValidUntil)
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }
    const readableDate = planValidUntil.toLocaleDateString('en-US', options)

    const handleUnsubscribe = async (service) => {
        // await removeNotification(service)  
        removeNotification(service)
    }

    const handleLogout = async () => {
        await logout()
        window.location.href = "/"
    }

    const UserRow = (props) => {

        return (
            <div className="">
                <div className="d-flex flex-row">
                    <div className="tw-font-bold w-100">
                        <p className="mb-1">{props.header}</p>
                        <div className="d-flex flex-row  align-items-center w-100">
                            <input className="w-50 form-control bg-body-tertiary" defaultValue={props.value} name={props.header} type={props.type} disabled />
                            {props.children}
                        </div>

                    </div>
                </div>

                <p className="tw-mt-1 tw-text-xs text-body-tertiary">{props.info}</p>

            </div>

        );
    };

    const planTextColor = user?.plan == "pro" ? "primary" : "secondary"

    return (
        <div className="tw-min-h-[80vh] bg-body-tertiary">
            <ConfirmModal
                show={showConfirmChangePasswordModal}
                title={"Change Password"}
                description={"A password reset link will be sent to your email, continue?"}
                onConfirm={onConfirmChangePasswordModalConfirm}
                onCancel={() => setShowConfirmChangePasswordModal(false)}
            />
            <ConfirmModal
                show={showChangePasswordSuccessfulModal}
                title={"Success"}
                confirmText={"OK"}
                onCancel={() => {
                    setShowChangePasswordSuccessfulModal(false)
                    setShowConfirmChangePasswordModal(false)
                }}
                onConfirm={() => {
                    setShowChangePasswordSuccessfulModal(false)
                    setShowConfirmChangePasswordModal(false)
                }}
                doCancel={false}
            >
                <Checkmark className={"text-primary"} />
                <p className="fs-5 text-center">Reset link has been sent!</p>
            </ConfirmModal>
            <Section className=" bg-body-tertiary ">
                <div>
                    <h1 className="display-5 fw-bold">Account</h1>
                    <p className="text-secondary fs-5">Manage your account settings.</p>
                </div>
                <div className="tw-flex tw-flex-col md:tw-flex-row  gap-2">
                    <div className="d-flex flex-row gap-2 flex-fill flex-wrap">
                        <div className="p-4 bg-body rounded-2 flex-grow-1 d-flex flex-column justify-content-between">
                            <div>
                                <h2 className="fw-bold">General</h2>
                                {/* <p>View and edit your profile information.</p> */}
                                <hr className="px-2 tw-max-w-full"></hr>
                            </div>
                            <div className="d-flex flex-column flex-wrap gap-3">
                                <UserRow header="Email" value={user.email} type="text"   >
                                    <a className="tw-no-underline " href="mailto:support@privacypolice.ai">
                                        <i className="ms-4 bi bi-pencil-square text-body"></i>
                                    </a>
                                </UserRow>
                                <UserRow header="Password" value={Math.random() * 10} type="password"  >
                                    <button className="ms-2 btn btn-link text-secondary" onClick={() => setShowConfirmChangePasswordModal(true)}>
                                        Change Password
                                    </button>
                                </UserRow>
                            </div>
                            <Link className="fs-5 text-danger" onClick={handleLogout}><i className="bi bi-arrow-left-short"></i>Logout</Link>
                        </div>
                        {/* <div className="p-4 bg-body rounded-2 flex-grow-1">
                            <h2 className="fw-bold">Notifications</h2>
                            <p>Manage your notifications </p>
                            <hr className="px-2 tw-max-w-xl"></hr>
                            <ul className="list-group list-group-flush ">
                                {notificationPolicies.map((policy, i) => (
                                    <li key={i} className="list-group-item d-flex justify-content-between align-items-center odd:tw-bg-body even:tw-bg-body-secondary">
                                        <div className="d-flex flex-row tw-w-fit gap-2">
                                            <Link to={`/policy/${policy.service}`} className="text-primary fw-bold">
                                                {policy.service_favicon ? <img className="tw-w-6 tw-h-6" src={policy.service_favicon} alt={policy.service} width="20" /> : <div className="rounded-circle tw-w-6 tw-h-6 bg-primary text-center w-full"><span className="text-white">{policy.service[0]}</span></div>}
                                            </Link>
                                            <p className="mb-0">{policy.service}</p>
                                        </div>
                                        <div className="d-flex flex-row tw-group tw-relative">
                                            <button className="btn tw-h-5 tw-w-5  text-primary tw-relative" onClick={() => handleUnsubscribe(policy.service)}><i className="bi bi-bell-fill tw-opacity-100 tw-absolute -tw-top-1 group-hover:tw-opacity-0 tw-transition-opacity"></i><i className="bi bi-bell-slash-fill tw-absolute -tw-top-[4.9px] tw-opacity-0 text-danger group-hover:tw-opacity-100 tw-transition-all"></i></button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            
                        </div>     */}

                    </div>

                    <div className="p-4 bg-body rounded-2 h-full flex-grow-1">
                        <div>
                            <h2 className="fw-bold"><span className="me-2">Plan</span><span className={`text-${planTextColor} fw-bold bg-${planTextColor}-subtle px-2 rounded-4`}>{capitalizeFirstLetter(user.plan)}</span></h2>
                        </div>
                        <hr className="px-2 tw-max-w-md"></hr>
                        <div className="d-flex flex-column gap-3 ">
                            {isFreeUser ? (
                                <div>
                                    <h2 className="fw-bold">Upgrade to <span className="text-primary bg-primary-subtle px-2 rounded-4">Pro</span></h2>
                                    <p>Unlock all features for only <strong className="text-primary">$2/mo</strong>!</p>
                                    <ProSubscriptionFeatures />

                                    <Link className="btn btn-primary" to={"/#pricing"}>Upgrade</Link>
                                </div>)
                                :
                                (
                                    <>
                                        <div className="d-flex flex-column justify-content-between">
                                            <p>{user.planCancelling ? "Expires" : "Renews"} on {readableDate}</p>
                                            {/* <p>Manage your subscription here</p> */}
                                            <ManageSubscriptionButton />
                                        </div>
                                    </>
                                )
                            }
                        </div>

                    </div>



                </div>

            </Section>



        </div>
    );
}
