import { useContext } from "react"
import { Modal } from 'react-bootstrap'
import { ApplicationContext } from '../providers/ApplicationProvider'
import SignUpForm from "../components/SignUpForm"
import SubscribeForm from "../components/SubscribeForm"
import { AuthContext } from "../providers/AuthProvider"
import ProSubscriptionFeatures from "../components/ProSubscriptionFeatures"

export default function SubscribeModal({ show, title }) {
    const { setShowSubscribeModal } = useContext(ApplicationContext)
    const {user, isGuestUser} = useContext(AuthContext)

    const onCancel = () => {
        setShowSubscribeModal(false)
    }

    const onConfirm = () => {

    }
    
    if(!user) return <></>

    return (
        <>
            <Modal dialogClassName="tw-max-w-sm" show={show} centered onHide={onCancel}>
                <SubscribeForm title={title} intent={isGuestUser ? "signup" : "subscribe"} />
            </Modal>
        </>

    )
}