import { useContext } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { ApplicationContext } from "../providers/ApplicationProvider"
import { API_URL } from "../Api"

export default function ManageSubscriptionButton({ buttonClassName }) {

    const { setShowSignUpModal, setShowSubscribeModal } = useContext(ApplicationContext)
    const { user, isGuestUser } = useContext(AuthContext)

    const onFormSubmit = e => {
        if (isGuestUser) {
            e.preventDefault()
            setShowSubscribeModal(true)
        }
    }

    return (
        <form method="POST" action={API_URL + "/create-customer-portal-session"} onSubmit={onFormSubmit}>
            <button type="submit" className={buttonClassName || "btn btn-primary fw-bold"}>Manage Subscription<i className="bi bi-arrow-right-short"></i></button>
        </form>
    )
}