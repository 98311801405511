import React from "react";
import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import './index.css';

import App from './App';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import { AuthProvider } from './providers/AuthProvider';
import { UserSettingsProvider } from './providers/UserSettingsProvider';
import CategoriesPage, { loader as categoriesLoader } from './routes/CategoriesPage';
import PolicyPage, { loader as policyLoader } from './routes/PolicyPage';
import HomePage, { loader as homeLoader } from './routes/HomePage';
import AboutPage from './routes/AboutPage';
import AccountPage from './routes/AccountPage';
import LoginPage from './routes/LoginPage';
import SignUpPage from './routes/SignUpPage';
import PrivacyPolicyPage from './routes/PrivacyPolicyPage';
import TermsOfServicePage from './routes/TermsOfService';
import { ApplicationProvider } from './providers/ApplicationProvider';
import RankingPage, { loader as rankingLoader } from './routes/RankingPage';
import CategoryPage, { loader as categoryLoader } from './routes/CategoryPage';
import FeaturePolicyPage from './routes/FeaturePolicyPage';
import PasswordResetRequestPage from "./routes/PasswordResetRequestPage";
import ChangePasswordPage from "./routes/ChangePasswordPage";
import ScanPolicyPage, { loader as scanPolicyLoader } from "./routes/ScanPolicyPage";
import CheckoutPage from "./routes/CheckoutPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthProvider />}>
      <Route element={<ApplicationProvider />}>
        <Route element={<UserSettingsProvider />}>
          <Route element={<App />}>
            <Route path="/" element={<HomePage />} loader={homeLoader} />
            <Route path="about" element={<AboutPage />} loader={homeLoader} />
            <Route path="account" element={<AccountPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="signup" element={<SignUpPage />} />
            <Route path="policies" element={<CategoriesPage />} loader={categoriesLoader} />
            <Route path="policies/:category" element={<CategoryPage />} loader={categoryLoader} />
            <Route path="policy/:policy" element={<PolicyPage />} loader={policyLoader} />
            <Route path="feature-policy" element={<FeaturePolicyPage />} />
            <Route path="scan-policy" element={<ScanPolicyPage />} loader={scanPolicyLoader} />
            <Route path="legal">
              <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="terms-of-service" element={<TermsOfServicePage />} />
            </Route>
            <Route path="checkout" element={<CheckoutPage />} />
          </Route>
        </Route>
      </Route>
      <Route path="reset-password" element={<PasswordResetRequestPage />} />
      <Route path="change-password" element={<ChangePasswordPage />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <RouterProvider router={router} />
  </>
);