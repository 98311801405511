import { Link } from "react-router-dom";

export default function LinkCard({ img_src, alt, title, to, text, action, color }) {
    return (
        <div className="card overflow-hidden rounded-3 tw-w-80">
            {img_src ?
                <img src={img_src} className="card-img-top" alt="..." /> :
                <div className={`w-100 tw-h-40 d-flex flex-row justify-content-center align-items-center bg-${color}-subtle`}>
                    <span className={`h3 fw-bold text-${color}-emphasis`}>{alt}</span>
                </div>
            }
            <div className="card-body">
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{text}</p>
                <Link to={to} className="btn btn-link stretched-link">{action}</Link>
            </div>
        </div>
    )
}