import React, { useContext, useEffect, useState } from "react";
import LoginForm from "../components/LoginForm";
import { Link } from "react-router-dom";
import { ApplicationContext } from "../providers/ApplicationProvider";

export default function LoginPage() {

  const { setShowSignUpModal } = useContext(ApplicationContext)

  useEffect(() => {
    setShowSignUpModal(false)
  }, [])

  return (
    <div className="d-flex flex-row justify-content-center w-100 px-3">

      <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center tw-max-w-6xl tw-min-h-[80vh]">
        <LoginForm />
      </div>
    </div>

  )
}

